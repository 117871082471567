@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";


body {
  background:#fff;
}   


img { max-width:100%;display: block; }

.clear-row-col12 > div:nth-child(0n+1) {  clear:left;margin-bottom:20px }
.clear-row-col6 > div:nth-child(2n+1) {  clear:left;margin-bottom:20px } 
.clear-row-col4 > div:nth-child(3n+1) {  clear:left;margin-bottom:20px }
.clear-row-col3 > div:nth-child(4n+1) {  clear:left;margin-bottom:20px }
.clear-row-col2 > div:nth-child(6n+1) {  clear:left;margin-bottom:20px }  

.cookie_warning{ background:#336072;padding: 10px 0px;position: fixed;width: 100%;bottom: 0;left: 0;z-index:9999;color:#fff; }
.cookie_warning .zamknij { color:#fff;text-align:right;display:block;cursor:pointer; }

@media screen and (max-width: 768px) {
  .cookie_warning .container { margin:6px }
}

/****************************** clear bootrap **************************************************/
#clear_bootstrap{
  .navbar{ min-height: 87px !important; }
  .navbar-inverse { border:0; }
  .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover { background:none; }
  .navbar-inverse  .navbar-nav > li > a.active { }
  .navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a { text-shadow:none;text-transform:uppercase;color:#4b4b4b;font-size:16px }
  .navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover { color:#4b4b4b }     
  .navbar-right { margin: 25px 0px 0px 0px; }
  .nav > li > a { padding:10px 27px } 
  .navbar-inverse .navbar-toggle { border:0; }
  .navbar-inverse .navbar-toggle .icon-bar { background-color:#21709D; }
  .navbar-toggle { background:none }
  .navbar-nav .open .dropdown-menu { background:#fff }
  .navbar-inverse { background-image: none }
  .navbar-inverse { background: #fff; }
  
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color:#fff}
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form { border-color: #101010; border: 0;}
  .navbar-toggle .icon-bar {display: block;width: 22px;height: 3px;border-radius: 1px; }
  .navbar-inverse .navbar-toggle { margin-top:25px }
  .dropdown-menu { border-radius:0 }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #000; }
  .navbar-brand > img { max-width: 200px;margin-top: 8px; }  
  #main-carousel .carousel-inner > .item { height: 500px; }
  #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 40%;right: 40%; }
  #main-carousel .carousel-caption { bottom: 40%; }
  #main-carousel .carousel-indicators {bottom: 70px;}
  .content_short_front { height: 123px;overflow: hidden; }
  #footer { background: rgb(33, 112, 157) none repeat scroll 0% 0%;color: rgb(255, 255, 255);margin-top: 50px; }
  #footer p { margin:10px 0 }
  
  .news_content_short { height: 143px;overflow: hidden; }
  .product_content_short { height: 143px;overflow: hidden; }
  
  @media screen and (max-width: 768px) {
    #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%; }
  }
  
  #bxslider1 .zobacz_wiecej { position: absolute; left:0; top:0; width:100%; height:100%; z-index:1000;background: rgb(51, 122, 183) none repeat scroll 0% 0%;
color:#fff;padding: 20px 30px;display: block;width: 170px; }
 
 

}

